import './Home.css';

function Home() {
    return (
        <div className="container-fluid hero">
            <div className="row">
                <div className="col p-0">
                    <div className="card bg-dark rounded-0 text-white d-flex">
                        <img className="img-fluid card-img d-none d-sm-none d-md-block" src="./img/Header1.png" alt="" />
                        <img className="img-fluid card-img d-sm-block d-md-none" src="./img/Header1_mobile.png" alt="" />
                        <div className="card-img-overlay text-center">
                            <h1 className="card-title h1_shadow mt-5 fw-bold display-1 text-lite">Steven Littrell</h1>
                            <h3 className="display-5 h3_shadow fw-light text-light">Full Stack Web Developer</h3>
                            <h5 className="fs-4 h5_shadow text-light fw-light">Designing solutions one byte at a time</h5>
                            <div className="row">
                                <div className="col pt-2">
                                    <a href="#blogPost" className="link-light">
                                        <svg className="arrow-shadow" xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="black" className="bi bi-caret-down-square" viewBox="0 0 16 16">
                                            <path d="M3.626 6.832A.5.5 0 0 1 4 6h8a.5.5 0 0 1 .374.832l-4 4.5a.5.5 0 0 1-.748 0l-4-4.5z" />
                                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-warning text-warning" id="blogPost">
                    .
                    </div>

                <div className="row">
                    <div className="">
                        <h1 >About Me</h1>
                        {/* <img id="plan" className="me-5 ms-5 mb-5" align="right" height="400" src="./img/ArchDrawing.jpeg" /> */}
                        <h5 className="text-muted pb-3">An Architect turned Teacher turned Web Developer</h5>
                        <p>
                        I always thought my dream was to become an architect. I loved to draw, doodle little floor plans, and build models. Everyone always told me that that’s what I should do. It’s even what I went to school for and got a degree in. Yet something happened my last year of college. I found myself working for a local non-profit focusing on art and science education through hands-on, experiential learning, and I fell in love. This was the place for me.</p>

                        <p>I never knew what I would be doing from day to day. I could be teaching a class or leading a field trip group through the hands-on discovery exhibits and planetarium, designing a postcard for an upcoming art exhibit or building a website, feeding Speedy the corn snake or fixing a computer. Every day was an adventure and I was ready for it!</p>

                        <p>I spent the next 15 years working in the non-profit sector until it happened again. That spark, that lightning strike of inspiration. We had just finished up a large capital project to expand our facility and I had been assigned the new maker space and digital media studio to oversee. It was in programing these spaces I discovered that it wasn’t just the variety that I enjoyed but the opportunity to learn something new. Diving deep into these technologies showed me the vast opportunities to investigate and absorb new concepts and ideas. There were so many frameworks and languages, problems to solve and things to design.</p>

                        <p>In hindsight, it shouldn’t have come as a surprise. I had always been interested in learning new things, taking up to scuba diving, learning to pick locks (just for fun!), even going so far as to get EMT certified because it sounded fun. I was hooked and then COVID struck. We had to close our doors for a time like so many other organizations. At first it was a blow but then I saw it as an opportunity. It gave me time to further my understanding. I decided to enroll in a full stack web development bootcamp and it was the best decision I could have made. Web development allowed for all of my passions; it incorporates design and creativity, problem-solving and critical thinking, and most importantly, an endless supply of new things to learn.</p>

                        <p>In the past 12 weeks I have learned 4 languages: HTML, CSS, JavaScript, and PHP; 3 frameworks: React, Laravel, and Bootstrap; and a handful of other tools: Postman, Git/Github, VS Code, etc. I can’t wait to see what’s next.</p>
                        <div className="row pt-4">
                            <div className="col-2">
                                <img height="60" src="./img/GitHub-Mark-120px-plus.png" />
                            </div>
                            <div className="col-2">
                                <img height="60" src="./img/linkedIn.png" />
                            </div>
                            <div className="col-2">
                                <img height="60" src="./img/twitter.png" />
                            </div>
                            <div className="col-2">
                                <img height="64" src="./img/Instagram.png" />
                            </div>
                            <div className="col-2">
                                <img height="60" src="./img/facebook.png" />
                            </div>

                        </div>
                        <div className="row pt-4">

                        </div>
                    </div>
                    <div className="col-5 offset-1">
                        {/* <img id="plan" height="500" src="./img/ArchDrawing.jpeg" /> */}
                    </div>
                </div>
                <div className="text-center">
                <div className="row row-cols-sm-1 row-cols-md-2 row-cols-lg-4 g-4 pt-4 pb-5">
                    <div className="col">
                        <img height="200" src="./img/berlin.png" />
                    </div>
                    <div className="col">
                        <img height="200" src="./img/dayofthedead.png" />
                    </div>
                    <div className="col">
                        <img height="200" src="./img/rafting.png" />
                    </div>
                    <div className="col">
                        <img height="200" src="./img/buda.png" />
                    </div>
                </div>
                </div>
                <div className="bg-dark text-dark p-5" id="blogPost">
                    .
                    </div>
                    <div className="bg-warning text-warning mb-5" id="blogPost">
                    .
                    </div>
            </div>
        </div>
    );
}

export default Home;